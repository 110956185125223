
// TODO move all in base-item-product.scss file
@import "./../scss/required";

:deep() {
    .list-badges {
        position: absolute;
        top: $spacer * .7;
        left: 0;
        max-width: 80%;
        transform: translateX(-1px);

        > * {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.img {
    position: relative;
    padding: map-get($spacers, 2) map-get($spacers, 2) 0 map-get($spacers, 2);

    .image-secondary {
        opacity: 0;
    }

    &:hover {
        .transition {
            .image-secondary {
                opacity: 1;
                transition: $transition-fade;
            }
        }
    }

    .btn-action {
        position: absolute;
        top: map-get($spacers, 1);
        right: map-get($spacers, 1);
        width: 30px;
        height: 30px;
        background-color: $white;
        border: $border-width solid $gray-300;
        border-radius: 50%;

        @include media-breakpoint-up(sm) {
            top: map-get($spacers, 3);
            right: map-get($spacers, 3);
            width: 35px;
            height: 35px;
        }
    }

    .btn-favorites {
        &.active {
            opacity: 1;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-link {
        color: $text-muted;
    }

    @include media-breakpoint-up(sm) {
        padding: $spacer $spacer 0 $spacer;
    }
}

.body {
    position: relative;
    padding: map-get($spacers, 2);

    > *:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
    }

    .container-variants {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
    }

    .list-inline-wrap :deep() {
        .list-inline-item {
            margin-right: calc(#{$list-inline-padding} / 2);
        }
    }

    @include media-breakpoint-up(sm) {
        padding: $spacer * 2 $spacer * 2 $spacer;

        .container-variants {
            transform: translateY(-50%);
        }
    }
}

.actions {
    padding-bottom: $spacer;
    text-align: center;
}

.title {
    display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
    overflow: hidden;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: calc(2em * $headings-line-height);

    &,
    a {
        color: $gray-900;
    }
}
