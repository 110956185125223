@use "sass:map";

@import "./../required";

.item-product {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: $secondary;
    text-align: left;
    cursor: pointer;
    background-color: $white;
    border: 1px solid transparent;
    border-radius: $border-radius-lg;

    content-visibility: auto;
    contain-intrinsic-size: auto 1px auto 300px;

    .slider-item & {
        content-visibility: visible;
    }

    .price-ref-wrapper {
        height: 1em;
    }

    &:hover {
        border-color: $gray-300;
    }

    @include media-breakpoint-up(sm) {
        .btn-favorites {
            opacity: 0;
        }

        &:hover {
            .btn-favorites {
                opacity: 1;
            }
        }
    }
}

.item-product-sold-out {
    .labels {
        .text-muted {
            padding: map.get($spacers, 1) map.get($spacers, 2);
            color: color-yiq($text-muted) !important; // override bootstrap
            background-color: $text-muted;
        }
    }

    .img {
        filter: grayscale(100%);
    }
}
