
@import './../scss/required';

.labels-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .label {
        font-size: $font-size-sm;
        font-weight: $font-weight-base;
    }
}
