
@use "sass:map";

@import "./../scss/required";

.list-variants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    * + * {
        margin-left: map.get($spacers, 2);
    }
}

.container-variants > * + * {
    margin-top: map.get($spacers, 2);
}

.circle {
    @include media-breakpoint-up(sm) {
        width: 25px;
        height: 25px;
        border-width: 2px;

        &.circle-hex {
            border-color: $white;
            box-shadow: 0 0 .4rem $gray-700;
        }
    }
}

.circle-text {
    display: none;
    font-size: $font-size-xs;
    line-height: $font-size-xs;

    @include media-breakpoint-up(sm) {
        display: flex;
    }
}
